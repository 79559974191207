<template>
    <div>
        <div class="flex-column justify-center align-items-center" style="height:100vh;width:100vw">
            <div class="title">404</div>
            <div class="bold font-size-24 margin-top-10">您访问的页面不存在哦！</div>
            <div class="margin-top-10">您可以点击下面的按钮返回主页。</div>
            <div class="margin-top-20 margin-bottom-30">
                <el-button type="primary" size="small" @click="gotoHome">返回主页</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        
    },
    methods:{
        gotoHome(){
            this.$router.replace({
              path: '/',
          })
        }
    }
}
</script>
<style scoped>
    .title{
        font-size: 100px;
        font-weight: bold;
    }
</style>